import Swal from 'sweetalert2/dist/sweetalert2';
import { i18n } from "../i18n";
import { csrfToken, setPropertyByPath } from "../utils";

export const cancelTransferConfirmation = async () => (
  Swal.fire({
    title: i18n.t('shared.are_you_sure'),
    text: i18n.t('drivers.unassign_partner_confirm'),
    showCancelButton: true,
    confirmButtonText: i18n.t('shared.confirm'),
    cancelButtonText: i18n.t('cancel'),
    heightAuto: false,
    icon: "question"
  })
)

export const initTransferConfirmation = async (driverId, body, onAccept = () => {}, onCancel = () => {}) => {
  const modalContainer = $('#modal-container');
  const loader = $('.data-preloader');
  loader.removeClass('d-none');

  const response = await fetch(`/drivers/${driverId}/transfer_confirm_modal`, {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': csrfToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const html = await response.text();
  modalContainer.html(html);
  const modal = modalContainer.children();

  return new Promise((resolve) => {
    modal.on('hide.bs.modal.transfer', () => {
      onCancel()
      resolve({ isConfirmed: false })
    });

    $('#transfer-accept').on('click', (e) => {
      $(e.currentTarget).prop('disabled', true);
      const transferDetails = {};
      $('#transfer-details-form').serializeArray().forEach(field => {
        setPropertyByPath(transferDetails, field.name, field.value);
      });
      delete transferDetails['authenticity_token'];

      onAccept(transferDetails);
      modal.off('hide.bs.modal.transfer').modal('hide');
      resolve({ isConfirmed: true, transferDetails: transferDetails })
    });

    loader.addClass('d-none');
    modal.modal('show');
  })
};

export const isTaskTransferred  = async (taskId) => {
  const res = await fetch(`/api/web/tasks/${taskId}/transfer_status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken()
    }
  })

  const body = await res.json()
  return body.is_transferred
}

export const visitsTransferStatus = async (visitIds) => {
  const res = await fetch(`/api/web/visits/bulk_transfer_status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken()
    },
    body: JSON.stringify({ ids: visitIds })
  })

  return await res.json()
}

export const tasksTransferStatus = async (taskIds) => {
  const res = await fetch(`/api/web/tasks/bulk_transfer_status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken()
    },
    body: JSON.stringify({ ids: taskIds })
  })

  return await res.json()
}
